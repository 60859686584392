@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap"');

body {
  background-color: white;
}

span {
    font-size: 1.2em;
}

img {
    border-radius: 5px;
}

h1 {
    font-size: 1.0em;
    font-family: Georgia;
    font-weight: normal;
    margin:0px;
    text-align: center;
}

.map {
    margin: auto;
    width: 20%;
}

.react-photo-gallery--gallery {
    margin: 3px;
    border: 1px solid gray;
    border-radius: 5px;
}

.icons {
    float:right;
    width: 10px;
}

.icons_page {
    width: 100%;
    padding: 10px;
}


.icons a {
    color: maroon;
    float: right;
    font-size: 1.2em;
    text-decoration: none;
    padding: 5px;
}

.icons_page a {
    color: maroon;
    font-size: 1.2em;
    text-decoration: none;
    padding: 5px;
}


.menu {
    text-align: center;
    width: 100%;
}

.menu button, .menu a {
    border: 1px solid maroon;
    background: white;
    color: #80122e;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    font-weight: bold;
    margin: 10px;
    padding: 10px 10px;
    text-align: center;
    text-transform: uppercase;
}

.pulldown {
    margin: 5px;
    display:none;
    cursor: pointer;
    background: white;
}

.pulldown button {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    background: white;
    border: none;
    border: 1px solid maroon;
}

.readmore {
    border: none;
    color: grey;
    display: inline-block;
    font-weight: bold;
    font-size: .8em;
    margin: 10px;
    text-align: center;
    background: white;
    cursor: pointer;
}


.heading {
    text-align: center;
    color: maroon;
    font-size: 1.4em;
    font-weight: none;
    padding: 5px;
    margin-bottom: 10px;
}

.heading img {
    height: 60px;
    width:  60px;
    float: left;
}


.small {
    font-size: 0.8em;
    font-family: Verdana;
    font-style: italic;
    color: rgb(60, 60, 60);
}

.small a {
    text-decoration: none;
    color: rgb(60, 60, 60);
    padding-right: 10px;
}


.imgmain {
        border: 1px solid #ddd;
        margin-left: auto;
        margin-right: auto;
        padding: 3px;
        width: 90%;
        height: 80%;
}

.imgmedium {
        width:  80%;
        border-radius: 10px;
        padding: 5px;
        border: 1px dotted #ddd;
}

.border_disable {
    border: none;
 }

.imgsmall {
        height: 15%;
        width:  15%;
        align: center;
        padding: 3px;
        margin: 10px;
}

.textimgcon {
    position: relative;
}

.textrel {
        position: absolute;
        top: 20%;
        left: 20%;
}



.info {
        margin: auto;
        border-radius: 5px;
        text-align: center;
        width: 90%;
}

.infotxt {
        border-radius: 5px;
        font-family: Garamond;
        line-height: 1.6;
        margin: 3px;
        padding: 3px;
}

.float_right {
    float: right;
    padding: 20px;
}

.float_left {
    float: left;
    padding: 20px;
}

.mosiac_wrapper {
    width: 95%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.mosiac_item {
    margin: auto;
    width: 15%;
    padding: 20px;
}


.infotitle {
        font-family: Philosopher;
        font-size: 1.2em;
        margin: 3px;
        color: maroon;
        line-height: 1.9;
        font-style: italic;
        text-align: center;
}


.title {
        color: maroon;
        font-size: 1.5em;
        font-family: Verdana;
        margin: 3px;
}

.tagline {
        color: maroon;
        font-family: Garamond;
        margin: 5px;
        padding: 3px;
}

.desctxt {
        border-radius: 8px;
        color: maroon;
        font-family: Garamond;
        font-size: 0.9em;
        padding: 3px;
        margin:  3px;
        line-height: 1.3;
        text-align: center;
}

.mosiaclarge {
  float: left;
  width: 70%;
  padding: 2px;
  background:  #faecec;
}

.labelbgtext {
  background:  #faecec;
  border-radius: 5px;
  padding: 5px;
}

.labelbg {
  background:  #faecec;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.mosiactext {
  float: left;
  width: 20%;
  padding: 5px;
  margin: 30px;
  background:  #faecec;
  border-radius: 5px;
  line-height: 2;
}



@media only screen and (max-width: 600px) {
  .menu {
    display: none;
  }

  .pulldown {
    display:block;
  }

  .pulldown:active + .menu {
    display: block; 
  }

  .menu:active {
    display: block; 
  }

  .menu button {
    border: 1px solid maroon;
    background: white;
    color: #80122e;
    display: inline-block;
    font-size: .8em;
    font-weight: bold;
    margin: 5px;
    padding: 10px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  .map {
    margin: auto;
    width: 80%;
  }

.mosiac_wrapper {
    display:inline;
}

.mosiac_item {
    width: 50%;
    padding: 20px;
}

.mosiaclarge {
    width: 95%;
}


.mosiactext {
  width: 85%;
  padding: 5px;
  background:  #faecec;
  border-radius: 5px;
  line-height: 2;
}


}


